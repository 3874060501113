<template>
<div class="column has-text-centered bio-panel">
  <figure class="image  is-inline-block has-space-below">
    <img  class="is-rounded"
          :src="pageData.fields.personReference.fields.image.fields.file.url + '?fit=scale&w=256&h=256'"
          :alt="pageData.fields.personReference.fields.name">
  </figure>

  <div class="title is-5">{{pageData.fields.personReference.fields.name}}</div>
  <div class="title is-5">{{pageData.fields.personReference.fields.title}}</div>
  <div class="app-panel-text">
    <p>{{pageData.fields.personReference.fields.shortBio}}</p>
  </div>
</div>
</template>

<script>
  export default {
    name: 'BioPanel',
    props: {
      pageData: Object,
      rootPageData: Object,
      background: String
    },
    computed: {
      file() {
        let f = this.pageData.fields.panelImage.fields.file;
        this.$log.debug("{BioPanel} f:", f);
        return f;
      },
      allFields() {
        let f = this.pageData.fields
        this.$log.debug("{BioPanel} f:", f);
        return f;
      },
      biotext() {
        return pageData.fields.personReference.fields.title
      }
    }
  }
</script>



<!--DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";
  @import "@/scss/_bulmaOverridden";

  .bio-panel {
    height: 100%;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;

    figure {
      max-width: 256px;
    }
  }


</style>
